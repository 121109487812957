<template>
  <div>
    <el-card :body-style="{ padding: '25px' }">
      <div slot="header">
        <div style="margin-left: 0px; display: flex">
          <div class="select2">
            <el-select
              v-model="selectTipoBusqueda"
              slot="prepend"
              style="width: 140px"
            >
              <el-option label="Servicio" :value="1"></el-option>
              <el-option label="Fecha" :value="2"></el-option>
              <el-option label="Confirmados" :value="3"></el-option>
              <el-option label="Realizados" :value="4"></el-option>
              <el-option label="Técnico" :value="5"></el-option>
              <el-option label="Cliente" :value="6"></el-option>
              <el-option label="Barrio" :value="7"></el-option>
            </el-select>
          </div>
          <div class="buscador2">
            <!-- Servicio -->
            <maca-input-delay
              v-show="selectTipoBusqueda == 1"
              v-model="descripcionServicio"
              placeholder="Escriba el servicio"
            ></maca-input-delay>
            <!-- Fecha -->
            <el-date-picker
              v-show="selectTipoBusqueda == 2"
              ref="datepicker"
              v-model="fecha"
              type="date"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              placeholder="Seleccionar fecha"
              @change="actualizarTablaFuncion()"
            ></el-date-picker>
            <!-- Confirmado -->
            <el-select
              v-show="selectTipoBusqueda == 3"
              v-model="confirmado"
              slot="prepend"
              style="width: 150px"
              @change="actualizarTablaFuncion()"
            >
              <el-option label="SI" :value="1"></el-option>
              <el-option label="NO" :value="0"></el-option>
            </el-select>
            <!-- Realizado -->
            <el-select
              v-show="selectTipoBusqueda == 4"
              v-model="realizado"
              slot="prepend"
              style="width: 150px"
              @change="actualizarTablaFuncion()"
            >
              <el-option label="SI" :value="1"></el-option>
              <el-option label="NO" :value="0"></el-option>
            </el-select>
            <!-- Tecnico -->
            <maca-select-box
              v-show="selectTipoBusqueda == 5"
              :url="urlTecnico"
              v-model="tecnico"
              :clearable="true"
              :necesitaParams="true"
              :getParams="getParamsTecnico"
              itemLabel="username"
              placeholder="Seleccione técnico"
              @change="actualizarTablaFuncion()"
            >
            </maca-select-box>
            <!-- Cliente -->
            <maca-select-box
              v-show="selectTipoBusqueda == 6"
              :url="urlCliente"
              v-model="cliente"
              :clearable="true"
              :necesitaParams="true"
              :getParams="getParamsCliente"
              :permitirInput="true"
              :itemLabel="['nombre', 'telefono']"
              placeholder="Buscar por teléfono"
              @change="actualizarTablaFuncion()"
            >
            </maca-select-box>
            <!-- barrio/seccion -->
            <maca-select-box
              v-show="selectTipoBusqueda == 7"
              :url="urlSeccion"
              v-model="seccion"
              :clearable="true"
              :necesitaParams="true"
              :getParams="getParamsSeccion"
              :permitirInput="true"
              :itemLabel="['descripcion']"
              placeholder="Barrio"
              @change="actualizarTablaFuncion()"
            >
            </maca-select-box>
          </div>
        </div>
        <el-button
          class="botonmasIcono"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right: 0px"
          @click="$refs.modalNuevo.abrir()"
          >Nuevo</el-button
        >
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="90" width="120" label="Fecha">
            <template slot-scope="props">
              <div v-if="props.row.detalleTurnoTecnico">
                <span
                  >{{
                    $maca.common.formatearFecha(
                      props.row.detalleTurnoTecnico.detalleTurno
                        .detalleTurnoGeneral.fecha
                    )
                  }}
                  -
                </span>
                <span
                  v-text="
                    $maca.common.formatearHora(
                      props.row.detalleTurnoTecnico.detalleTurno.horaInicio
                    ) +
                    ' a ' +
                    $maca.common.formatearHora(
                      props.row.detalleTurnoTecnico.detalleTurno.horaFin
                    )
                  "
                ></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="90" label="Ubicación">
            <template slot-scope="props">
              <div v-if="props.row.detalleTurnoTecnico">
                <span
                  v-if="
                    props.row.detalleTurnoTecnico.detalleTurno
                      .detalleTurnoGeneral.detalleArea
                  "
                  >{{
                    props.row.detalleTurnoTecnico.detalleTurno
                      .detalleTurnoGeneral.detalleArea.descripcion
                  }}</span
                >
                <span
                  v-if="
                    props.row.detalleTurnoTecnico.detalleTurno
                      .detalleTurnoGeneral.detalleSubrea != null
                  "
                  >,
                  {{
                    props.row.detalleTurnoTecnico.detalleTurno
                      .detalleTurnoGeneral.detalleSubrea.descripcion
                  }}</span
                >
              </div>
              <p>Barrio: {{ props.row.detalleSeccion.descripcion }}</p>
            </template>
          </el-table-column>
          <el-table-column :min-width="90" label="Cliente">
            <template slot-scope="props">
              <span v-if="props.row.detalleClienteReserva != null">
                Nombre: <b>{{ props.row.detalleClienteReserva.nombre }}</b>
              </span>
              <br />
              <span v-if="props.row.detalleClienteReserva != null">
                Dirección:
                <b>{{ props.row.detalleClienteReserva.domicilio }}</b>
              </span>
              <br />
              <span v-if="props.row.detalleClienteReserva != null">
                Teléfono: <b>{{ props.row.detalleClienteReserva.telefono }}</b>
              </span>
            </template>
          </el-table-column>
          <el-table-column :min-width="90" label="Servicio">
            <template slot-scope="props">
              <span v-text="props.row.descripcionServicio"></span>
              <el-popover placement="top" width="260">
                <el-input
                  placeholder="Agregar comentario"
                  style="width: 80%"
                  v-model="agregarDescipcion"
                  :controls="false"
                ></el-input>
                <el-button
                  style="margin-left: 12px"
                  type="primary"
                  circle
                  icon="el-icon-plus"
                  @click="agregarDescripcion(props.row)"
                ></el-button>
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  slot="reference"
                  @click="visible = !visiblePopover"
                ></el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column :min-width="90" width="110" label="Importe">
            <template slot-scope="props">
              <span
                >$
                {{
                  (
                    parseFloat(props.row.montoProductos) +
                    parseFloat(props.row.montoServicios)
                  ).toFixed(2)
                }}
              </span>
              <el-popover placement="top" width="260">
                <el-input-number
                  placeholder="Agregar monto"
                  style="width: 80%"
                  v-model="montoAgregado"
                  :step="10"
                  :controls="false"
                ></el-input-number>
                <el-button
                  style="margin-left: 12px"
                  type="primary"
                  circle
                  icon="el-icon-plus"
                  @click="agregarMonto(props.row)"
                ></el-button>
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  slot="reference"
                  @click="visible = !visiblePopover"
                ></el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="Productos" width="90">
            <template slot-scope="props">
              <el-button
                type="primary"
                circle
                @click="
                  $refs.modalDetalleProducto.abrir(
                    props.row.id,
                    props.row.detalleProducto
                  )
                "
                :disabled="props.row.detalleProducto.length <= 0"
              >
                <i class="el-icon-view"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Turno confirmado" width="99" align="center">
            <template slot-scope="props">
              <el-switch
                v-model="props.row.confirmado"
                :active-value="1"
                :inactive-value="0"
                @change="toogleConfirmar(props.row)"
                :disabled="
                  props.row.bloqueado
                    ? !$store.getters.tienePermiso('M_USE_DES')
                    : !$store.getters.tienePermiso('M_USE_BLO')
                "
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="Turno realizado" width="99" align="center">
            <template slot-scope="props">
              <el-switch
                v-model="props.row.realizado"
                :active-value="1"
                :inactive-value="0"
                @change="toogleRealizado(props.row)"
                :disabled="
                  props.row.bloqueado
                    ? !$store.getters.tienePermiso('M_USE_DES')
                    : !$store.getters.tienePermiso('M_USE_BLO')
                "
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="Pago registrado" width="99" align="center">
            <template slot-scope="props">
              <el-switch
                v-model="props.row.pagoRegistrado"
                :active-value="1"
                :inactive-value="0"
                @change="tooglePagoRegistrado(props.row)"
                :disabled="
                  props.row.bloqueado
                    ? !$store.getters.tienePermiso('M_USE_DES')
                    : !$store.getters.tienePermiso('M_USE_BLO')
                "
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="Entregada" width="99" align="center">
            <template slot-scope="props">
              <el-switch
                v-model="props.row.entregada"
                :active-value="1"
                :inactive-value="0"
                @change="toogleEntregada(props.row)"
                :disabled="
                  props.row.bloqueado
                    ? !$store.getters.tienePermiso('M_USE_DES')
                    : !$store.getters.tienePermiso('M_USE_BLO')
                "
              ></el-switch>
            </template>
          </el-table-column>
          <!-- <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="primary"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                disabled
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column> -->
          <el-table-column label="Descargar" width="90">
            <template slot-scope="props">
              <el-button type="primary" @click="abrirRecibo(props.row)" circle>
                <i class="el-icon-download"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
    <modal-detalle-producto
      ref="modalDetalleProducto"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-detalle-producto>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";
import ModalDetalleProducto from "../retiros/modales/detalleProducto.vue";

export default {
  name: "reserva",
  components: {
    ModalNuevo,
    ModalModificar,
    ModalDetalleProducto,
  },
  data() {
    return {
      urlTabla: "/reserva/obtenerTodos",
      urlTecnico: "/user/obtenerTodos",
      urlCliente: "/clienteReserva/obtenerTodos",
      urlSeccion: "/seccion/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      selectTipoBusqueda: 1,

      //filtros
      descripcionServicio: "",
      fecha: null,
      confirmado: null,
      realizado: null,
      tecnico: null,
      cliente: null,
      seccion: null,

      //agregar monto
      montoAgregado: 0,
      visiblePopover: false,

      //agregar descripcion
      agregarDescipcion: "",
    };
  },
  methods: {
    async abrirRecibo(fila) {
      window.open(
        this.$maca.api.APIURL +
          "/reserva/descargarReserva?descargar=0&reservaID=" +
          fila.id,
        "Reserva"
      );
    },
    getParamsSeccion(query) {
      let params = { descripcion: query };
      return params;
    },
    getParamsCliente(query) {
      let params = { telefono: query };
      return params;
    },
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar la reserva."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post("/reserva/eliminar", params);
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "Reserva borrada con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleConfirmar(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.confirmado) {
        respuestaApi = await this.$maca.api.post("/reserva/confirmar", params);
      } else {
        respuestaApi = await this.$maca.api.post("/reserva/cancelar", params);
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleRealizado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id, realizado: fila.realizado };
      let respuestaApi = null;

      respuestaApi = await this.$maca.api.post(
        "/reserva/actualizarRealizado",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleEntregada(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id, entregada: fila.entregada };
      let respuestaApi = null;

      respuestaApi = await this.$maca.api.post(
        "/reserva/actualizarEntregada",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async tooglePagoRegistrado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id, pagoRegistrado: fila.pagoRegistrado };
      let respuestaApi = null;

      respuestaApi = await this.$maca.api.post(
        "/reserva/actualizarPagoRegistrado",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async agregarMonto(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id, montoServicios: this.montoAgregado };
      let respuestaApi = null;

      respuestaApi = await this.$maca.api.post(
        "/reserva/actualizarMonto",
        params
      );
      //

      this.visiblePopover = false;
      this.montoAgregado = 0;
      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async agregarDescripcion(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id, descripcionServicio: this.agregarDescipcion };
      let respuestaApi = null;

      respuestaApi = await this.$maca.api.post(
        "/reserva/agregarDescripcion",
        params
      );
      //

      this.agregarDescipcion = "";
      this.visiblePopover = false;
      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    getParamsTecnico() {
      let params = { rolID: 5 };
      return params;
    },
    actualizarTablaFuncion() {
      if (this.selectTipoBusqueda == 5) {
        if (this.tecnico) this.paramsTabla = { userID: this.tecnico.id };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      } else if (this.selectTipoBusqueda == 6) {
        if (this.cliente) this.paramsTabla = { clienteID: this.cliente.id };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      } else if (this.selectTipoBusqueda == 2) {
        if (this.fecha) this.paramsTabla = { fecha: this.fecha };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      } else if (this.selectTipoBusqueda == 3) {
        if (this.confirmado != null)
          this.paramsTabla = { confirmado: this.confirmado };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      } else if (this.selectTipoBusqueda == 4) {
        if (this.realizado != null)
          this.paramsTabla = { realizado: this.realizado };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      } else if (this.selectTipoBusqueda == 7) {
        if (this.seccion != null)
          this.paramsTabla = { seccionID: this.seccion.id };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      }
    },
  },
  watch: {
    descripcionServicio() {
      if (this.selectTipoBusqueda == 1) {
        this.paramsTabla = { descripcionServicio: this.descripcionServicio };
        this.actualizarTabla = true;
      } else {
        this.paramsTabla = {};
        this.actualizarTabla = true;
      }
    },
  },
};
</script>
