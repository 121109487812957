var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: { titulo: "Nueva reserva", impedirClose: _vm.impedirClose }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-position": "left",
                "label-width": "115px"
              }
            },
            [
              _c(
                "el-steps",
                { attrs: { space: 200, active: _vm.active, simple: "" } },
                [
                  _c("el-step", {
                    attrs: { title: "Cliente", icon: "el-icon-user" }
                  }),
                  _c("el-step", {
                    attrs: { title: "Turno", icon: "el-icon-date" }
                  }),
                  _c("el-step", {
                    attrs: { title: "Bicicletas", icon: "el-icon-bicycle" }
                  }),
                  _c("el-step", {
                    attrs: {
                      title: "Productos",
                      icon: "el-icon-shopping-bag-2"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active == 0,
                      expression: "active == 0"
                    }
                  ],
                  staticStyle: { padding: "30px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Buscar cliente", prop: "cliente" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("maca-select-box-cliente", {
                            ref: "selectBoxCliente",
                            model: {
                              value: _vm.form.cliente,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "cliente", $$v)
                              },
                              expression: "form.cliente"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm.form.cliente
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Nombre" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cliente.nombre,
                              callback: function($$v) {
                                _vm.$set(_vm.form.cliente, "nombre", $$v)
                              },
                              expression: "form.cliente.nombre"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.cliente
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Teléfono" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cliente.telefono,
                              callback: function($$v) {
                                _vm.$set(_vm.form.cliente, "telefono", $$v)
                              },
                              expression: "form.cliente.telefono"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.cliente
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Domicilio" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cliente.domicilio,
                              callback: function($$v) {
                                _vm.$set(_vm.form.cliente, "domicilio", $$v)
                              },
                              expression: "form.cliente.domicilio"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.cliente
                    ? _c(
                        "el-form-item",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.active = 1
                                }
                              }
                            },
                            [_vm._v("Continuar")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active == 1,
                      expression: "active == 1"
                    }
                  ],
                  staticStyle: { padding: "30px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Provincia" } },
                    [
                      _c("maca-select-box", {
                        ref: "selectBoxArea",
                        attrs: { url: _vm.urlArea, itemLabel: "descripcion" },
                        on: { change: _vm.seleccionarArea },
                        model: {
                          value: _vm.form.area,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "area", $$v)
                          },
                          expression: "form.area"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.area != null,
                          expression: "form.area != null"
                        }
                      ],
                      attrs: { label: "Localidad" }
                    },
                    [
                      _c("maca-select-box", {
                        ref: "selectBoxSubarea",
                        attrs: {
                          necesitaParams: true,
                          getParams: _vm.getParamsSubarea,
                          url: _vm.urlSubarea,
                          itemLabel: "descripcion"
                        },
                        on: { change: _vm.seleccionarSubarea },
                        model: {
                          value: _vm.form.subarea,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "subarea", $$v)
                          },
                          expression: "form.subarea"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.subarea != null,
                          expression: "form.subarea != null"
                        }
                      ],
                      attrs: { label: "Barrio" }
                    },
                    [
                      _c("maca-select-box", {
                        ref: "selectBoxSeccion",
                        attrs: {
                          necesitaParams: true,
                          getParams: _vm.getParamsSeccion,
                          url: _vm.urlSeccion,
                          itemLabel: "descripcion"
                        },
                        on: { change: _vm.seleccionarSeccion },
                        model: {
                          value: _vm.form.seccion,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "seccion", $$v)
                          },
                          expression: "form.seccion"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.seccion != null,
                          expression: "form.seccion != null"
                        }
                      ],
                      attrs: { label: "Fecha" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          format: "dd/MM/yyyy",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "Seleccionar"
                        },
                        on: { change: _vm.seleccionarFecha },
                        model: {
                          value: _vm.form.fecha,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "fecha", $$v)
                          },
                          expression: "form.fecha"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.form.fecha != null
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Turno" } },
                        [
                          _c("maca-select-box", {
                            ref: "selectBoxTurno",
                            attrs: {
                              necesitaParams: true,
                              getParams: _vm.getParamsTurno,
                              url: _vm.urlTurnoTecnico,
                              "item-label": ["horaInicio", "horaFin"]
                            },
                            model: {
                              value: _vm.form.turno,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "turno", $$v)
                              },
                              expression: "form.turno"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.active = 0
                            }
                          }
                        },
                        [_vm._v("Atrás")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.turno,
                              expression: "form.turno"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.active = 2
                            }
                          }
                        },
                        [_vm._v("Continuar")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active == 2,
                      expression: "active == 2"
                    }
                  ],
                  staticStyle: { padding: "30px" }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Descripción del servicio",
                        prop: "descripcionServicio"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.descripcionServicio,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "descripcionServicio", $$v)
                          },
                          expression: "form.descripcionServicio"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Bicicletas" } },
                    _vm._l(this.form.tiposBicis, function(item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c("el-checkbox", {
                                    attrs: { label: item.descripcion },
                                    model: {
                                      value: item.agregado,
                                      callback: function($$v) {
                                        _vm.$set(item, "agregado", $$v)
                                      },
                                      expression: "item.agregado"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-input-number", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.agregado,
                                        expression: "item.agregado"
                                      }
                                    ],
                                    attrs: { size: "mini", min: 1 },
                                    model: {
                                      value: item.cantidad,
                                      callback: function($$v) {
                                        _vm.$set(item, "cantidad", $$v)
                                      },
                                      expression: "item.cantidad"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "el-form-item",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.active = 1
                            }
                          }
                        },
                        [_vm._v("Atrás")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.form.descripcionServicio != null &&
                                _vm.form.descripcionServicio != "" &&
                                _vm.comprobarBicisAgregadas(),
                              expression:
                                "\n              form.descripcionServicio != null &&\n              form.descripcionServicio != '' &&\n              comprobarBicisAgregadas()\n            "
                            }
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.active = 3
                            }
                          }
                        },
                        [_vm._v("Continuar")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active == 3,
                      expression: "active == 3"
                    }
                  ],
                  staticStyle: { padding: "30px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Productos" } },
                    _vm._l(this.form.productos, function(item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-checkbox", {
                                    attrs: { label: item.nombre },
                                    on: { change: _vm.productoAgregado },
                                    model: {
                                      value: item.agregado,
                                      callback: function($$v) {
                                        _vm.$set(item, "agregado", $$v)
                                      },
                                      expression: "item.agregado"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "20px" } },
                                    [_vm._v(" $" + _vm._s(item.precio) + " ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-input-number", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.agregado,
                                        expression: "item.agregado"
                                      }
                                    ],
                                    attrs: { size: "mini", min: 1 },
                                    on: { change: _vm.productoAgregado },
                                    model: {
                                      value: item.cantidad,
                                      callback: function($$v) {
                                        _vm.$set(item, "cantidad", $$v)
                                      },
                                      expression: "item.cantidad"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.total != 0,
                          expression: "form.total != 0"
                        }
                      ]
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            "font-size": "20px"
                          }
                        },
                        [_vm._v(" Total: $" + _vm._s(_vm.form.total) + " ")]
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.active = 2
                            }
                          }
                        },
                        [_vm._v("Atrás")]
                      ),
                      _c("maca-boton-guardar", {
                        attrs: { onSubmit: _vm.onSubmit }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }