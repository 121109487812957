<template>
  <div>
    <maca-modal titulo="Nueva reserva" :impedirClose="impedirClose" ref="modal">
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="left"
        label-width="115px"
      >
        <el-steps :space="200" :active="active" simple>
          <el-step title="Cliente" icon="el-icon-user"></el-step>
          <el-step title="Turno" icon="el-icon-date"></el-step>
          <el-step title="Bicicletas" icon="el-icon-bicycle"></el-step>
          <el-step title="Productos" icon="el-icon-shopping-bag-2"></el-step>
        </el-steps>

        <!-- CLIENTES -->
        <div v-show="active == 0" style="padding: 30px">
          <el-form-item label="Buscar cliente" prop="cliente">
            <div style="display: flex">
              <maca-select-box-cliente
                ref="selectBoxCliente"
                v-model="form.cliente"
              ></maca-select-box-cliente>
            </div>
          </el-form-item>
          <el-form-item v-if="form.cliente" label="Nombre">
            <el-input v-model="form.cliente.nombre"> </el-input>
          </el-form-item>
          <el-form-item v-if="form.cliente" label="Teléfono">
            <el-input v-model="form.cliente.telefono"> </el-input>
          </el-form-item>
          <el-form-item v-if="form.cliente" label="Domicilio">
            <el-input v-model="form.cliente.domicilio"> </el-input>
          </el-form-item>
          <el-form-item v-if="form.cliente" style="text-align: right">
            <el-button type="primary" @click="active = 1">Continuar</el-button>
          </el-form-item>
        </div>
        <!-- TURNO -->
        <div v-show="active == 1" style="padding: 30px">
          <el-form-item label="Provincia">
            <maca-select-box
              ref="selectBoxArea"
              v-model="form.area"
              :url="urlArea"
              itemLabel="descripcion"
              @change="seleccionarArea"
            ></maca-select-box>
          </el-form-item>
          <el-form-item v-show="form.area != null" label="Localidad">
            <maca-select-box
              ref="selectBoxSubarea"
              :necesitaParams="true"
              :getParams="getParamsSubarea"
              v-model="form.subarea"
              :url="urlSubarea"
              itemLabel="descripcion"
              @change="seleccionarSubarea"
            ></maca-select-box>
          </el-form-item>
          <el-form-item v-show="form.subarea != null" label="Barrio">
            <maca-select-box
              ref="selectBoxSeccion"
              :necesitaParams="true"
              :getParams="getParamsSeccion"
              v-model="form.seccion"
              :url="urlSeccion"
              itemLabel="descripcion"
              @change="seleccionarSeccion"
            ></maca-select-box>
          </el-form-item>
          <el-form-item v-show="form.seccion != null" label="Fecha">
            <el-date-picker
              v-model="form.fecha"
              type="date"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              placeholder="Seleccionar"
              @change="seleccionarFecha"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item v-if="form.fecha != null" label="Turno">
            <maca-select-box
              ref="selectBoxTurno"
              :necesitaParams="true"
              :getParams="getParamsTurno"
              v-model="form.turno"
              :url="urlTurnoTecnico"
              :item-label="['horaInicio', 'horaFin']"
            ></maca-select-box>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="active = 0">Atrás</el-button>
            <el-button v-show="form.turno" type="primary" @click="active = 2"
              >Continuar</el-button
            >
          </el-form-item>
        </div>
        <!-- BICICLETAS -->
        <div v-show="active == 2" style="padding: 30px">
          <el-form-item
            label="Descripción del servicio"
            prop="descripcionServicio"
          >
            <el-input
              type="textarea"
              v-model="form.descripcionServicio"
            ></el-input>
          </el-form-item>
          <el-form-item label="Bicicletas">
            <div v-for="(item, index) in this.form.tiposBicis" :key="index">
              <el-row>
                <el-col :span="10">
                  <el-checkbox
                    :label="item.descripcion"
                    v-model="item.agregado"
                  ></el-checkbox>
                </el-col>
                <el-col :span="12">
                  <el-input-number
                    v-show="item.agregado"
                    size="mini"
                    :min="1"
                    v-model="item.cantidad"
                  ></el-input-number>
                </el-col>
              </el-row>
            </div>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="active = 1">Atrás</el-button>
            <el-button
              v-show="
                form.descripcionServicio != null &&
                form.descripcionServicio != '' &&
                comprobarBicisAgregadas()
              "
              type="primary"
              @click="active = 3"
              >Continuar</el-button
            >
          </el-form-item>
        </div>
        <!-- PRODUCTOS -->
        <div v-show="active == 3" style="padding: 30px">
          <el-form-item label="Productos">
            <div v-for="(item, index) in this.form.productos" :key="index">
              <el-row>
                <el-col :span="12">
                  <el-checkbox
                    :label="item.nombre"
                    v-model="item.agregado"
                    @change="productoAgregado"
                  ></el-checkbox>
                  <span style="margin-left: 20px"> ${{ item.precio }} </span>
                </el-col>
                <el-col :span="12">
                  <el-input-number
                    v-show="item.agregado"
                    size="mini"
                    v-model="item.cantidad"
                    :min="1"
                    @change="productoAgregado"
                  ></el-input-number>
                </el-col>
              </el-row>
            </div>
          </el-form-item>
          <el-form-item v-show="form.total != 0">
            <span style="font-weight: bold; font-size: 20px">
              Total: ${{ form.total }}
            </span>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="active = 2">Atrás</el-button>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </div>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nueva-reserva",
  data() {
    return {
      active: 0,
      urlTurnoTecnico: "/turno/obtenerTodosSelect",
      urlArea: "/area/obtenerTodos",
      urlSubarea: "/subarea/obtenerTodos",
      urlSeccion: "/turnoSeccion/obtenerTodosSelect",
      form: {
        tiposBicis: [],
        productos: [],
        fecha: null,
        turno: null,
        area: null,
        subarea: null,
        seccion: null,
        cliente: null,
        descripcionServicio: null,
        detalleTipoBici: [],
        detalleProducto: [],
        total: 0,
      },
      impedirClose: false,
      formRules: {},
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
        this.form.fecha = null;
        this.form.area = null;
        this.form.subarea = null;
        this.form.cliente = null;
        this.form.tiposBicis = [];
        this.form.productos = [];
        this.active = 0;
      }

      this.getTiposBicis();
      this.getProductos();
    },
    cerrar() {
      this.active = 0;
      this.$refs.modal.cerrar();
    },
    async getTiposBicis() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get("/tipoBici/obtenerTodos");

      if (respuestaApi != null) {
        respuestaApi.datos.forEach((element) => {
          this.form.tiposBicis.push({
            id: element.id,
            descripcion: element.descripcion,
            cantidad: 1,
            agregado: false,
          });
        });
      }

      this.cargando = false;
    },
    async getProductos() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get("/producto/obtenerTodos");

      if (respuestaApi != null) {
        respuestaApi.datos.forEach((element) => {
          this.form.productos.push({
            id: element.id,
            nombre: element.nombre,
            descripcion: element.descripcion,
            precio: element.precio,
            cantidad: 1,
            agregado: false,
          });
        });
      }

      this.cargando = false;
    },
    seleccionarArea() {
      this.form.subarea = null;
      this.form.fecha = null;
      this.form.turno = null;
      if (this.form.area != null) {
        this.$refs.selectBoxSubarea.recargar();
      }
    },
    seleccionarSubarea() {
      this.form.fecha = null;
      this.form.turno = null;
      this.form.seccion = null;
      if (this.form.subarea != null) {
        this.$refs.selectBoxSeccion.recargar();
      }
    },
    seleccionarSeccion() {
      this.form.fecha = null;
      this.form.turno = null;
    },
    seleccionarFecha() {
      this.form.turno = null;
      if (this.form.fecha != null && this.form.seccion != null) {
        this.$refs.selectBoxTurno.recargar();
      }
    },
    productoAgregado() {
      if (this.form.productos != []) {
        this.form.total = 0;
        this.form.productos.forEach((element) => {
          if (element.agregado == true) {
            this.form.total =
              this.form.total +
              parseFloat(element.precio) * parseInt(element.cantidad);
          }
        });
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.form.tiposBicis.forEach((element) => {
          if (element.agregado == true) {
            for (let index = 0; index < element.cantidad; index++) {
              this.form.detalleTipoBici.push({
                tipoBiciID: element.id,
              });
            }
          }
        });

        this.form.productos.forEach((element) => {
          if (element.agregado == true) {
            this.form.detalleProducto.push({
              productoID: element.id,
              cantidad: element.cantidad,
              precio: element.precio,
            });
          }
        });

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        descripcionServicio: this.form.descripcionServicio,
        nombreCliente: this.form.cliente.nombre,
        telefonoCliente: this.form.cliente.telefono,
        domicilioCliente: this.form.cliente.domicilio,
        turnoTecnicoID: this.form.turno.turnoTecnicoID,
        subareaID: this.form.subarea.id,
        seccionID: this.form.seccion.id,
        detalleTipoBici: JSON.stringify(this.form.detalleTipoBici),
        detalleProducto: JSON.stringify(this.form.detalleProducto),
      };

      let respuestaApi = await this.$maca.api.post("/reserva/crear", params);
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Reserva creada con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
    getParamsSubarea() {
      if (this.form.area) {
        let params = {
          areaID: this.form.area.id,
        };
        return params;
      }
    },
    getParamsSeccion() {
      if (this.form.subarea) {
        let params = {
          subareaID: this.form.subarea.id,
        };
        return params;
      }
    },
    getParamsTurno() {
      let params = {
        seccionID: this.form.seccion.id,
        fecha: this.form.fecha,
      };
      return params;
    },
    comprobarBicisAgregadas() {
      var agregados = false;
      this.form.tiposBicis.forEach((element) => {
        if (element.agregado == true) {
          agregados = true;
        }
      });
      return agregados;
    },
  },
};
</script>
