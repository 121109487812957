var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", { attrs: { "body-style": { padding: "25px" } } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c(
              "div",
              { staticStyle: { "margin-left": "0px", display: "flex" } },
              [
                _c(
                  "div",
                  { staticClass: "select2" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "140px" },
                        attrs: { slot: "prepend" },
                        slot: "prepend",
                        model: {
                          value: _vm.selectTipoBusqueda,
                          callback: function($$v) {
                            _vm.selectTipoBusqueda = $$v
                          },
                          expression: "selectTipoBusqueda"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "Servicio", value: 1 }
                        }),
                        _c("el-option", {
                          attrs: { label: "Fecha", value: 2 }
                        }),
                        _c("el-option", {
                          attrs: { label: "Confirmados", value: 3 }
                        }),
                        _c("el-option", {
                          attrs: { label: "Realizados", value: 4 }
                        }),
                        _c("el-option", {
                          attrs: { label: "Técnico", value: 5 }
                        }),
                        _c("el-option", {
                          attrs: { label: "Cliente", value: 6 }
                        }),
                        _c("el-option", {
                          attrs: { label: "Barrio", value: 7 }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "buscador2" },
                  [
                    _c("maca-input-delay", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectTipoBusqueda == 1,
                          expression: "selectTipoBusqueda == 1"
                        }
                      ],
                      attrs: { placeholder: "Escriba el servicio" },
                      model: {
                        value: _vm.descripcionServicio,
                        callback: function($$v) {
                          _vm.descripcionServicio = $$v
                        },
                        expression: "descripcionServicio"
                      }
                    }),
                    _c("el-date-picker", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectTipoBusqueda == 2,
                          expression: "selectTipoBusqueda == 2"
                        }
                      ],
                      ref: "datepicker",
                      attrs: {
                        type: "date",
                        format: "dd/MM/yyyy",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "Seleccionar fecha"
                      },
                      on: {
                        change: function($event) {
                          return _vm.actualizarTablaFuncion()
                        }
                      },
                      model: {
                        value: _vm.fecha,
                        callback: function($$v) {
                          _vm.fecha = $$v
                        },
                        expression: "fecha"
                      }
                    }),
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTipoBusqueda == 3,
                            expression: "selectTipoBusqueda == 3"
                          }
                        ],
                        staticStyle: { width: "150px" },
                        attrs: { slot: "prepend" },
                        on: {
                          change: function($event) {
                            return _vm.actualizarTablaFuncion()
                          }
                        },
                        slot: "prepend",
                        model: {
                          value: _vm.confirmado,
                          callback: function($$v) {
                            _vm.confirmado = $$v
                          },
                          expression: "confirmado"
                        }
                      },
                      [
                        _c("el-option", { attrs: { label: "SI", value: 1 } }),
                        _c("el-option", { attrs: { label: "NO", value: 0 } })
                      ],
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTipoBusqueda == 4,
                            expression: "selectTipoBusqueda == 4"
                          }
                        ],
                        staticStyle: { width: "150px" },
                        attrs: { slot: "prepend" },
                        on: {
                          change: function($event) {
                            return _vm.actualizarTablaFuncion()
                          }
                        },
                        slot: "prepend",
                        model: {
                          value: _vm.realizado,
                          callback: function($$v) {
                            _vm.realizado = $$v
                          },
                          expression: "realizado"
                        }
                      },
                      [
                        _c("el-option", { attrs: { label: "SI", value: 1 } }),
                        _c("el-option", { attrs: { label: "NO", value: 0 } })
                      ],
                      1
                    ),
                    _c("maca-select-box", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectTipoBusqueda == 5,
                          expression: "selectTipoBusqueda == 5"
                        }
                      ],
                      attrs: {
                        url: _vm.urlTecnico,
                        clearable: true,
                        necesitaParams: true,
                        getParams: _vm.getParamsTecnico,
                        itemLabel: "username",
                        placeholder: "Seleccione técnico"
                      },
                      on: {
                        change: function($event) {
                          return _vm.actualizarTablaFuncion()
                        }
                      },
                      model: {
                        value: _vm.tecnico,
                        callback: function($$v) {
                          _vm.tecnico = $$v
                        },
                        expression: "tecnico"
                      }
                    }),
                    _c("maca-select-box", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectTipoBusqueda == 6,
                          expression: "selectTipoBusqueda == 6"
                        }
                      ],
                      attrs: {
                        url: _vm.urlCliente,
                        clearable: true,
                        necesitaParams: true,
                        getParams: _vm.getParamsCliente,
                        permitirInput: true,
                        itemLabel: ["nombre", "telefono"],
                        placeholder: "Buscar por teléfono"
                      },
                      on: {
                        change: function($event) {
                          return _vm.actualizarTablaFuncion()
                        }
                      },
                      model: {
                        value: _vm.cliente,
                        callback: function($$v) {
                          _vm.cliente = $$v
                        },
                        expression: "cliente"
                      }
                    }),
                    _c("maca-select-box", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectTipoBusqueda == 7,
                          expression: "selectTipoBusqueda == 7"
                        }
                      ],
                      attrs: {
                        url: _vm.urlSeccion,
                        clearable: true,
                        necesitaParams: true,
                        getParams: _vm.getParamsSeccion,
                        permitirInput: true,
                        itemLabel: ["descripcion"],
                        placeholder: "Barrio"
                      },
                      on: {
                        change: function($event) {
                          return _vm.actualizarTablaFuncion()
                        }
                      },
                      model: {
                        value: _vm.seccion,
                        callback: function($$v) {
                          _vm.seccion = $$v
                        },
                        expression: "seccion"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-button",
              {
                staticClass: "botonmasIcono",
                staticStyle: { "margin-right": "0px" },
                attrs: { icon: "el-icon-plus", type: "primary", round: "" },
                on: {
                  click: function($event) {
                    return _vm.$refs.modalNuevo.abrir()
                  }
                }
              },
              [_vm._v("Nuevo")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "maca-datatable",
              {
                attrs: {
                  url: _vm.urlTabla,
                  params: _vm.paramsTabla,
                  actualizar: _vm.actualizarTabla,
                  bloquear: _vm.bloquearTabla
                },
                on: {
                  "update:actualizar": function($event) {
                    _vm.actualizarTabla = $event
                  },
                  "update:bloquear": function($event) {
                    _vm.bloquearTabla = $event
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { "min-width": 90, width: "120", label: "Fecha" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.detalleTurnoTecnico
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$maca.common.formatearFecha(
                                        props.row.detalleTurnoTecnico
                                          .detalleTurno.detalleTurnoGeneral
                                          .fecha
                                      )
                                    ) + " - "
                                  )
                                ]),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$maca.common.formatearHora(
                                        props.row.detalleTurnoTecnico
                                          .detalleTurno.horaInicio
                                      ) +
                                        " a " +
                                        _vm.$maca.common.formatearHora(
                                          props.row.detalleTurnoTecnico
                                            .detalleTurno.horaFin
                                        )
                                    )
                                  }
                                })
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { "min-width": 90, label: "Ubicación" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.detalleTurnoTecnico
                            ? _c("div", [
                                props.row.detalleTurnoTecnico.detalleTurno
                                  .detalleTurnoGeneral.detalleArea
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          props.row.detalleTurnoTecnico
                                            .detalleTurno.detalleTurnoGeneral
                                            .detalleArea.descripcion
                                        )
                                      )
                                    ])
                                  : _vm._e(),
                                props.row.detalleTurnoTecnico.detalleTurno
                                  .detalleTurnoGeneral.detalleSubrea != null
                                  ? _c("span", [
                                      _vm._v(
                                        ", " +
                                          _vm._s(
                                            props.row.detalleTurnoTecnico
                                              .detalleTurno.detalleTurnoGeneral
                                              .detalleSubrea.descripcion
                                          )
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _c("p", [
                            _vm._v(
                              "Barrio: " +
                                _vm._s(props.row.detalleSeccion.descripcion)
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { "min-width": 90, label: "Cliente" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.detalleClienteReserva != null
                            ? _c("span", [
                                _vm._v(" Nombre: "),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      props.row.detalleClienteReserva.nombre
                                    )
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _c("br"),
                          props.row.detalleClienteReserva != null
                            ? _c("span", [
                                _vm._v(" Dirección: "),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      props.row.detalleClienteReserva.domicilio
                                    )
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _c("br"),
                          props.row.detalleClienteReserva != null
                            ? _c("span", [
                                _vm._v(" Teléfono: "),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      props.row.detalleClienteReserva.telefono
                                    )
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { "min-width": 90, label: "Servicio" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(props.row.descripcionServicio)
                            }
                          }),
                          _c(
                            "el-popover",
                            { attrs: { placement: "top", width: "260" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "80%" },
                                attrs: {
                                  placeholder: "Agregar comentario",
                                  controls: false
                                },
                                model: {
                                  value: _vm.agregarDescipcion,
                                  callback: function($$v) {
                                    _vm.agregarDescipcion = $$v
                                  },
                                  expression: "agregarDescipcion"
                                }
                              }),
                              _c("el-button", {
                                staticStyle: { "margin-left": "12px" },
                                attrs: {
                                  type: "primary",
                                  circle: "",
                                  icon: "el-icon-plus"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.agregarDescripcion(props.row)
                                  }
                                }
                              }),
                              _c("el-button", {
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  icon: "el-icon-edit"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.visible = !_vm.visiblePopover
                                  }
                                },
                                slot: "reference"
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { "min-width": 90, width: "110", label: "Importe" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", [
                            _vm._v(
                              "$ " +
                                _vm._s(
                                  (
                                    parseFloat(props.row.montoProductos) +
                                    parseFloat(props.row.montoServicios)
                                  ).toFixed(2)
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "el-popover",
                            { attrs: { placement: "top", width: "260" } },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "80%" },
                                attrs: {
                                  placeholder: "Agregar monto",
                                  step: 10,
                                  controls: false
                                },
                                model: {
                                  value: _vm.montoAgregado,
                                  callback: function($$v) {
                                    _vm.montoAgregado = $$v
                                  },
                                  expression: "montoAgregado"
                                }
                              }),
                              _c("el-button", {
                                staticStyle: { "margin-left": "12px" },
                                attrs: {
                                  type: "primary",
                                  circle: "",
                                  icon: "el-icon-plus"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.agregarMonto(props.row)
                                  }
                                }
                              }),
                              _c("el-button", {
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  icon: "el-icon-edit"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.visible = !_vm.visiblePopover
                                  }
                                },
                                slot: "reference"
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Productos", width: "90" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                circle: "",
                                disabled: props.row.detalleProducto.length <= 0
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.modalDetalleProducto.abrir(
                                    props.row.id,
                                    props.row.detalleProducto
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-view" })]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "Turno confirmado",
                    width: "99",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              disabled: props.row.bloqueado
                                ? !_vm.$store.getters.tienePermiso("M_USE_DES")
                                : !_vm.$store.getters.tienePermiso("M_USE_BLO")
                            },
                            on: {
                              change: function($event) {
                                return _vm.toogleConfirmar(props.row)
                              }
                            },
                            model: {
                              value: props.row.confirmado,
                              callback: function($$v) {
                                _vm.$set(props.row, "confirmado", $$v)
                              },
                              expression: "props.row.confirmado"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "Turno realizado",
                    width: "99",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              disabled: props.row.bloqueado
                                ? !_vm.$store.getters.tienePermiso("M_USE_DES")
                                : !_vm.$store.getters.tienePermiso("M_USE_BLO")
                            },
                            on: {
                              change: function($event) {
                                return _vm.toogleRealizado(props.row)
                              }
                            },
                            model: {
                              value: props.row.realizado,
                              callback: function($$v) {
                                _vm.$set(props.row, "realizado", $$v)
                              },
                              expression: "props.row.realizado"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "Pago registrado",
                    width: "99",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              disabled: props.row.bloqueado
                                ? !_vm.$store.getters.tienePermiso("M_USE_DES")
                                : !_vm.$store.getters.tienePermiso("M_USE_BLO")
                            },
                            on: {
                              change: function($event) {
                                return _vm.tooglePagoRegistrado(props.row)
                              }
                            },
                            model: {
                              value: props.row.pagoRegistrado,
                              callback: function($$v) {
                                _vm.$set(props.row, "pagoRegistrado", $$v)
                              },
                              expression: "props.row.pagoRegistrado"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Entregada", width: "99", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              disabled: props.row.bloqueado
                                ? !_vm.$store.getters.tienePermiso("M_USE_DES")
                                : !_vm.$store.getters.tienePermiso("M_USE_BLO")
                            },
                            on: {
                              change: function($event) {
                                return _vm.toogleEntregada(props.row)
                              }
                            },
                            model: {
                              value: props.row.entregada,
                              callback: function($$v) {
                                _vm.$set(props.row, "entregada", $$v)
                              },
                              expression: "props.row.entregada"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Descargar", width: "90" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", circle: "" },
                              on: {
                                click: function($event) {
                                  return _vm.abrirRecibo(props.row)
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-download" })]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Borrar", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                circle: "",
                                disabled: !_vm.$store.getters.tienePermiso(
                                  "B_USE"
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.eliminar(props.row.id)
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-delete" })]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-detalle-producto", {
        ref: "modalDetalleProducto",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }